import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OrderTableContext from './context';
import useAuthState from '~hooks/useAuthState';
import {
  GetHandoverTableViewParams,
  GetHandoverTableViewResponse,
  GET_HANDOVER_TABLE_VIEWS
} from '~apollo/queries/getHandoverTableViews';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  AddHandoverTableViewRequest,
  AddHandoverTableViewResponse,
  ADD_HANDOVER_TABLE_VIEW
} from '~apollo/mutations/addHandoverTableView';
import {
  DeleteHandoverTableViewRequest,
  DeleteHandoverTableViewResponse,
  DELETE_HANDOVER_TABLE_VIEW
} from '~apollo/mutations/deleteHandoverTableView';
import { isNonEmptyString } from '~helpers/stringHelper';
import { useSnackbar } from '@polestar/component-warehouse-react';
import useDatoState from '~hooks/useDatoState';
import {
  UpdateHandoverTableViewRequest,
  UpdateHandoverTableViewResponse,
  UPDATE_HANDOVER_TABLE_VIEW
} from '~apollo/mutations/updateHandoverTableView';
import { usePersistedState } from '~hooks/usePersistedState';
import {
  GetUserProfilesParams,
  GetUserProfilesResponse,
  GET_USER_PROFILES
} from '~apollo/queries/getUserProfiles';
import {
  GetOrderTableConfigRequest,
  GetOrderTableConfigResponse,
  GET_ORDER_TABLE_CONFIG,
  OrderTableColumn
} from '~apollo/queries/getOrderTableConfig';
import {
  FILTER_OPTIONS,
  HandoverFilter,
  HandoverFilterAttrib,
  HandoverFilterType,
  ValueType
} from '~enums/Filters';
import { SearchRequest } from '~apollo/queries/getHandovers';
import { cloneDeep } from 'lodash';
import {
  handleAnyAsFilterValue,
  handleBatchIDFilters,
  handleDatesFilters,
  handleHandoverMethodFilters,
  handleHandoverStateFilters,
  handleLocationFilters,
  handleNotesCountFilters,
  handleVistaBrandStatusFilters
} from '~helpers/orderTableHelper';
import useMarketState from '~hooks/useMarketState';
import gtmHelper from '~helpers/gtmHelper';
import { GetLocationsResponse, GET_LOCATIONS } from '~apollo/queries/getLocations';
import { DealerLocation } from '~apollo/queries/getLocation';
import { HISTORY_VIEW } from '~enums/OrderTable';

export interface ViewObject {
  id: string;
  label: string;
  columns: Array<string>;
  editable: boolean;
  filters: Array<HandoverFilter>;
  sortKey: {
    key: HandoverFilterAttrib;
    isBackwardsQuery: boolean;
  };
}

export const DEFAULT_VIEW = {
  id: 'defaultView',
  label: 'defaultView',
  columns: [],
  editable: false,
  filters: [],
  sortKey: {
    key: HandoverFilterAttrib.ESTIMATED_ARRIVAL,
    isBackwardsQuery: true
  }
};

const OrderTableContextProvider = (props: any) => {
  const { user, userLocation, userLanguage, userMarketId, isMarketView, selectedTimezone } =
    useAuthState();
  const { addSnackbar } = useSnackbar();
  const { text } = useDatoState();
  const [activeRange, setActiveRange] = useState<[string, string]>(['', '']);
  const [userViews, setUserViews] = useState<Array<ViewObject>>();
  const [staticViews, setStaticViews] = useState<Array<ViewObject>>();
  const [originalActiveView, setOriginalActiveView] = useState<ViewObject>({
    ...DEFAULT_VIEW
  });
  const [initialViewHasBeenSet, setInitialViewHasBeenSet] = useState(false);

  const [allTableColumns, setAllTableColumns] = useState<Array<OrderTableColumn>>([]);

  const [possibleLocations, setPossibleLocations] = useState<Array<DealerLocation>>([]);

  const [allTableFilterAttributes, setAllTableFilterAttributes] = useState<
    Array<HandoverFilterAttrib>
  >([]);
  const [lastActiveViewId, setLastActiveViewId] = usePersistedState('lastActiveViewId', '0');
  const [activeView, setActiveView] = useState<ViewObject>({ ...DEFAULT_VIEW });
  const [searchValue, setSearchValue] = useState('');

  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { countries } = useMarketState();
  // user defined views
  const [getTableViews, { loading: isLoadingTableViews }] = useLazyQuery<
    GetHandoverTableViewResponse,
    GetHandoverTableViewParams
  >(GET_HANDOVER_TABLE_VIEWS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        userProfileId: user.id
      }
    },
    onCompleted: data => {
      const defaultView = {
        id: 'defaultView',
        label: 'defaultView',
        columns: activeView.columns,
        editable: true,
        filters: [],
        sortKey: {
          key: HandoverFilterAttrib.HANDOVER_DATE,
          isBackwardsQuery: true
        }
      };

      const views = data.response.tableViews?.map(x => {
        // Remove all old filters not supported any longer.
        const validFilters = x.tableViewDetails.filters.filter(filter =>
          FILTER_OPTIONS.find(
            option =>
              option.id === filter.field &&
              option.operators.find(operator => operator.id === filter.type)
          )
        );

        // Parse into ViewObject
        const viewFilters: Array<HandoverFilter> = [];
        validFilters.forEach(filter => {
          if (filter.field)
            viewFilters.push({
              attrib: filter.field,
              type: filter.type,
              values: filter.values
            });
        });

        const view: ViewObject = {
          id: x.id,
          columns: x.tableViewDetails.columns,
          label: x.tableViewDetails.label,
          filters: viewFilters,
          sortKey:
            x.tableViewDetails.sortings.length > 0
              ? {
                  key: x.tableViewDetails.sortings[0].field,
                  isBackwardsQuery: Boolean(x.tableViewDetails.sortings[0].isSortOrderDescending)
                }
              : {
                  key: HandoverFilterAttrib.HANDOVER_DATE,
                  isBackwardsQuery: true
                },
          editable: true
        };

        return {
          ...defaultView,
          ...view,
          id: x.id,
          editable: true
        };
      }) as Array<ViewObject>;

      setUserViews(views.sort((a, b) => (a.label > b.label ? 1 : -1)));
    }
  });

  const { loading: isLoadingStaticViews } = useQuery<
    GetOrderTableConfigResponse,
    GetOrderTableConfigRequest
  >(GET_ORDER_TABLE_CONFIG, {
    variables: {
      language: userLanguage,
      marketId: isMarketView ? 'DEFAULT_MARKET_VIEW' : userLocation.marketId
    },
    context: { dato: true },
    onError: err => console.warn(err),
    onCompleted: ({ globalConfig = [], marketConfig = [] }) => {
      const config = marketConfig.length > 0 ? marketConfig[0] : globalConfig[0];
      setAllTableColumns(config.availableColumns.map(col => col));
      setAllTableFilterAttributes(
        config.availableFilters.map(attr => attr.attribute as HandoverFilterAttrib)
      );
      setActiveView(view => ({
        ...view,
        filters: view.filters.filter(filter =>
          config.availableFilters
            .map(attr => attr.attribute as HandoverFilterAttrib)
            .includes(filter.attrib)
        )
      }));

      const views: Array<ViewObject> = config.views.map(view => ({
        id: view.id,
        editable: false,
        label: isNonEmptyString(view.name) ? view.name : view.fallbackName,
        columns: view.columns.map(col => col.name),
        filters: view.filters.map(filter => ({
          attrib: filter.attribute.attribute as HandoverFilterAttrib,
          type: filter.operator as HandoverFilterType,
          values: filter.values.split(',')
        })),
        sortKey: {
          key: view.sortKey as HandoverFilterAttrib,
          isBackwardsQuery: view.isBackwardsQuery || false
        }
      }));
      setStaticViews([...views, HISTORY_VIEW]);
    }
  });

  const queryFilters = useMemo(() => {
    if (activeView.filters) {
      //Handle filters with special cases if they exist
      let updatedFilters = handleHandoverMethodFilters(cloneDeep(activeView.filters));
      updatedFilters = handleHandoverStateFilters(updatedFilters);
      updatedFilters = handleNotesCountFilters(updatedFilters);
      updatedFilters = handleVistaBrandStatusFilters(updatedFilters);
      updatedFilters = handleBatchIDFilters(updatedFilters);
      updatedFilters = handleAnyAsFilterValue(updatedFilters);
      const convertedFilters = updatedFilters.map(filter => {
        return {
          ...filter,
          values: filter.values?.filter(value => isNonEmptyString(value))
        };
      });

      return convertedFilters;
    }
    return [];
  }, [activeView.filters]);

  const queryVariables: SearchRequest = useMemo(
    () => ({
      input: {
        searchValue: searchValue,
        from: 0,
        size: 20,
        sortings: [
          {
            field: activeView.sortKey.key,
            isSortOrderDescending: activeView.sortKey.isBackwardsQuery
          }
        ],
        filters: [
          handleLocationFilters(isMarketView, isMarketView ? userMarketId ?? '' : userLocation.id),
          ...handleDatesFilters(
            activeView.sortKey.key,
            activeRange[0],
            activeRange[1],
            isMarketView ? selectedTimezone : userLocation.timezone
          )
        ].concat(
          // Backwards compatibility for user views
          queryFilters.map(filter => ({
            type: filter?.type as HandoverFilterType,
            field: filter?.attrib as HandoverFilterAttrib,
            values: filter?.values as Array<ValueType>
          }))
        )
      }
    }),
    [
      activeRange,
      activeView.sortKey.isBackwardsQuery,
      activeView.sortKey.key,
      isMarketView,
      queryFilters,
      searchValue,
      selectedTimezone,
      userLocation.id,
      userLocation.timezone,
      userMarketId
    ]
  );

  const [addHandoverTableView, { loading: isCreatingTableView }] = useMutation<
    AddHandoverTableViewResponse,
    AddHandoverTableViewRequest
  >(ADD_HANDOVER_TABLE_VIEW);

  const [deleteHandoverTableView, { loading: isDeletingTableView }] = useMutation<
    DeleteHandoverTableViewResponse,
    DeleteHandoverTableViewRequest
  >(DELETE_HANDOVER_TABLE_VIEW);

  const [updateHandoverTableView, { loading: isUpdatingTableView }] = useMutation<
    UpdateHandoverTableViewResponse,
    UpdateHandoverTableViewRequest
  >(UPDATE_HANDOVER_TABLE_VIEW);

  const { data: agentsData, loading: isLoadingAgents } = useQuery<
    GetUserProfilesResponse,
    GetUserProfilesParams
  >(GET_USER_PROFILES, {
    variables: {
      input: {
        isBackwardsQuery: false,
        paginationToken: null,
        locationIdFilter: isMarketView ? null : userLocation.id,
        marketIdFilter: isMarketView ? userMarketId : null,
        roleIdFilter: null,
        nameFilter: null
      }
    },
    onError: err => {
      console.error(err);
      addSnackbar({
        content: text('FetchAgentsError'),
        error: true
      });
    }
  });

  useEffect(() => {
    getTableViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fetchLocations, { loading: isLoadingLocations }] = useLazyQuery<GetLocationsResponse>(
    GET_LOCATIONS,
    {
      onCompleted: data => {
        const selectableLocations = data.locations
          .filter(loc => loc.marketId === userMarketId)
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        setPossibleLocations(selectableLocations);

        setActiveView(view => ({
          ...view,
          filters: view.filters.filter(filter =>
            filter.attrib === HandoverFilterAttrib.PDI_PDS_LOCATION ||
            filter.attrib === HandoverFilterAttrib.HANDOVER_LOCATION
              ? filter.values?.every(val => selectableLocations.some(loc => loc.id === val))
              : true
          )
        }));
      },
      onError: error => console.error(error)
    }
  );

  useEffect(() => {
    if (isNonEmptyString(userMarketId) && countries && countries?.length > 0) fetchLocations();
  }, [countries, fetchLocations, userMarketId]);

  useEffect(() => {
    if (!isLoading) {
      if (activeView.id !== lastActiveViewId) {
        setLastActiveViewId(activeView.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView.id]);

  useEffect(() => {
    if (!initialViewHasBeenSet && userViews !== undefined && staticViews !== undefined) {
      let lastView = [...userViews, ...staticViews].find(x => x.id == lastActiveViewId);

      if (!lastView) {
        if (staticViews.length > 0) {
          lastView = staticViews[0];
        } else {
          lastView = DEFAULT_VIEW;
        }
      }

      setActiveView(cloneDeep(lastView));
      setOriginalActiveView(cloneDeep(lastView));
      setInitialViewHasBeenSet(true);
    }
  }, [initialViewHasBeenSet, isLoading, lastActiveViewId, staticViews, text, userViews]);

  useEffect(() => {
    setIsLoading(
      isLoadingTableViews ||
        isCreatingTableView ||
        isDeletingTableView ||
        isUpdatingTableView ||
        isLoadingAgents ||
        isLoadingStaticViews ||
        isLoadingLocations
    );
  }, [
    isCreatingTableView,
    isDeletingTableView,
    isLoadingAgents,
    isLoadingLocations,
    isLoadingStaticViews,
    isLoadingTableViews,
    isUpdatingTableView
  ]);

  useEffect(() => {
    activeView.columns.sort();
    originalActiveView.columns.sort();
    const isEdited = JSON.stringify(activeView) !== JSON.stringify(originalActiveView);
    setIsEdited(isEdited);
    if (isEdited) {
      gtmHelper.pushOrdersUpdate(activeView);
    }
  }, [activeView, originalActiveView]);

  const updateTableView = useCallback(
    async (updatedView: ViewObject) =>
      await updateHandoverTableView({
        variables: {
          input: {
            id: updatedView.id,
            tableViewDetails: {
              label: updatedView.label,
              filters: updatedView.filters.map(filter => ({
                field: filter.attrib,
                type: filter.type,
                values: filter.values as Array<ValueType>
              })),
              columns: updatedView.columns,
              sortings: updatedView.sortKey
                ? [
                    {
                      field: updatedView.sortKey.key,
                      isSortOrderDescending: updatedView.sortKey.isBackwardsQuery
                    }
                  ]
                : []
            }
          }
        }
      })
        .then(resp => {
          if (resp.errors === undefined) {
            addSnackbar({ content: text('TableViewUpdated'), closeOnClick: true });
            getTableViews();
          }
        })
        .catch(e => {
          addSnackbar({ content: text('TableViewUpdateError'), error: true });
          console.error(e);
        }),
    [addSnackbar, getTableViews, text, updateHandoverTableView]
  );

  const handleSaveView = useCallback(() => {
    setOriginalActiveView(cloneDeep(activeView));
    updateTableView(cloneDeep(activeView));
  }, [activeView, updateTableView]);

  const handleDeleteView = useCallback(
    async (id: string) =>
      await deleteHandoverTableView({ variables: { input: { id: id } } })
        .then(() => {
          addSnackbar({ content: text('TableViewDeleted'), closeOnClick: true });
          getTableViews();
          if (id === activeView.id) {
            if (staticViews && staticViews.length > 0) {
              setActiveView(staticViews[0]);
              setOriginalActiveView(staticViews[0]);
            } else {
              setActiveView(cloneDeep(DEFAULT_VIEW));
              setOriginalActiveView(cloneDeep(DEFAULT_VIEW));
            }
          }
        })
        .catch(e => {
          addSnackbar({ content: e, error: true });
          console.error(e);
        }),
    [activeView.id, addSnackbar, deleteHandoverTableView, getTableViews, staticViews, text]
  );

  const handleCreateTableView = useCallback(
    async (newView: ViewObject) => {
      // Parse from ViewObject to HandoverTableViewDetails

      await addHandoverTableView({
        variables: {
          input: {
            tableViewDetails: {
              label: newView.label,
              filters: newView.filters.map(filter => ({
                field: filter.attrib,
                type: filter.type,
                values: filter.values as Array<ValueType>
              })),
              columns: newView.columns,
              sortings: newView.sortKey
                ? [
                    {
                      field: newView.sortKey.key,
                      isSortOrderDescending: newView.sortKey.isBackwardsQuery
                    }
                  ]
                : []
            }
          }
        }
      })
        .then(resp => {
          addSnackbar({ content: text('TableViewSaved'), closeOnClick: true });
          getTableViews();
          if (resp.data && resp.data.addedHandoverTableView) {
            setActiveView({
              ...newView,
              id: resp.data.addedHandoverTableView.id
            });
            setOriginalActiveView({
              ...newView,
              id: resp.data.addedHandoverTableView.id
            });
          }
        })
        .catch(e => {
          addSnackbar({ content: e, error: true });
          console.error(e);
        });
    },
    [addHandoverTableView, addSnackbar, getTableViews, text]
  );

  const handleResetView = useCallback(() => {
    setActiveView({ ...originalActiveView });
  }, [originalActiveView, setActiveView]);

  const providerValue = {
    allTableColumns,
    allTableFilterAttributes,
    queryVariables,
    isLoading,
    originalActiveView,
    setOriginalActiveView,
    activeRange,
    setActiveRange,
    userViews: userViews ?? [],
    setUserViews,
    staticViews: staticViews ?? [],
    activeView,
    setActiveView,
    searchValue,
    setSearchValue,
    isEdited,
    handleCreateTableView,
    handleSaveView,
    handleDeleteView,
    handleResetView,
    agentsData,
    possibleLocations,
    updateTableView,
    isCreatingTableView,
    isDeletingTableView,
    isUpdatingTableView
  };

  return <OrderTableContext.Provider {...props} value={providerValue} />;
};

export { OrderTableContext };
export default OrderTableContextProvider;
